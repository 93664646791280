<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <section>
          <section><img loading="lazy" class="alignnone wp-image-23731 aligncenter" src="@/assets/news/25/1.jpg" alt="图片" width="571" height="380"  sizes="(max-width: 571px) 100vw, 571px"></section>
        </section>
        <section>
          <section>
            <section>葡萄牙</section>
            <section>近期，葡萄牙移民局(SEF)公布了新的黄金签证数据，在2022年6月份，共计155位主申请人和204位附属申请人获批葡萄牙黄金签证，<strong>获批总人数为359人</strong>，总投资额约7,792万欧元。获批人数创疫情以来单月新高！其中，<strong>来自中国和美国的主申请人均为30人，排名位列首位，</strong>其次土耳其（14人）、印度（11人）、巴西（10人）。从获批的主申请人的数量可以看出，<strong>今年开年截至目前，美国申请人和中国申请人是主要的申请来源国，越来越多的投资者关注着葡萄牙黄金签证。</strong><img loading="lazy" class="wp-image-23732 aligncenter" src="@/assets/news/25/2.png" alt="图片" width="598" height="1495"  sizes="(max-width: 598px) 100vw, 598px"><p></p>
            </section>
          </section>
          <section>从投资方式上来看，<strong>在155位申请人中，有126位申请人选择了房产投资项目，总占比82.5%，其中58位选择投资了50万欧元房产，68位投资了35万欧元房产</strong>。</section>
          <section></section>
          <section>此外，葡萄牙移民局也公布了2012年10月黄金居留政策推行以来的整体数据，数据显示，截至2022年6月，累计获批主申请人10,903位，获批附属申请人17,956位。其中获批的中国家庭已经突破5,139人，占比近一半，可见中国投资者对葡萄牙项目的喜爱和认可。</section>
          <section><strong>&nbsp;</strong></section>
          <section>
            <section>
              <section><img loading="lazy" class="wp-image-23733 aligncenter" src="@/assets/news/25/3.png" alt="图片" width="660" height="1650"  sizes="(max-width: 660px) 100vw, 660px"></section>
            </section>
          </section>
          <section></section>
          <p>同时，葡萄牙黄金签证项目<strong>累计吸引海外投资约64.16亿欧元，其中房产投资额达到57.59亿欧元</strong>。可见房产投资仍然是主要投资方式，而且不仅中国投资者，全球海外投资者也都看好葡萄牙的房产一路向好的发展趋势，对葡萄牙房产有着浓厚的兴趣。更重要的是投资葡萄牙黄金签证项目在拥有身份之余，<strong>不仅可以自由出行欧洲，还可以在葡萄牙当地自由读书、工作、经商、定居，一步到位满足身份配置、教育规划、养老医疗、置业安家等多重需求，助力财富升值。</strong></p>
          <p>据葡萄牙国家统计局（INE）数据显示，葡萄牙2021年出售的商业地产的价格上涨5.1%，创造了自2010年以来的增幅历史新高。商业地产销售价格的上涨紧随住房价格上涨的趋势，2021年葡萄牙住宅地产价格上涨了9.4%。而价格上涨并没有阻止交易量的攀升，6月23日，葡萄牙国家统计局(INE)指出，<strong>葡萄牙在2022年第一季度交割的房产交易额约为81亿欧元，同比上涨了13%！</strong>葡萄牙房屋销售量比2021年同期增长了25.8%，售出43,544套住房。</p>
          <p>目前，葡萄牙新政已正式实施，提高了基金投资门槛，缩小了住宅类购房移民的地理范围，50万欧以上住宅类房产或35万欧以上翻新住宅类房产，只能在内陆地区、马德拉群岛和亚速尔群岛购买；但在<strong>里斯本、波尔图等热门城市仍通过购买商业类地产，申请黄金签证，投资门槛依然为35万欧。</strong></p>
          <p><strong>这意味着未来海外购房移民也将愈加严格，涨价或将成为趋势。政策红利未来或将越来越少，建议如有需求的投资人可以早做准备，联系我们，抓紧上车。</strong></p>
        </section>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news25",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:25,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>